<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="d-none brand-logo">
        <h2 class="brand-text text-primary ml-1">
          Licensing</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        md="12"
        class="p-lg-5"
      >
        <div
          class="brand-img"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
            class="w-75"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="6"
        md="12"
        class="d-flex align-items-center auth-bg px-lg-3 px-md-0 px-sm-0 p-lg-5 p-md-0 p-sm-01"
      >
        <b-col
          sm="8"
          md="9"
          lg="12"
          class="px-xl-5 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Licensing starts here 🚀
          </b-card-title>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form
              class="auth-register-form mt-1"
              @submit.prevent="validationForm"
            >
              <b-row>
                <b-col cols="6">
                  <!-- firstname -->
                  <b-form-group
                    label="First name"
                    label-for="firstname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input
                        id="firstname"
                        v-model="first_name"
                        :state="errors.length > 0 ? false : null"
                        name="register-firstname"
                        placeholder="john"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <!-- lastname -->
                  <b-form-group
                    label="Last name"
                    label-for="lastname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="last_name"
                        :state="errors.length > 0 ? false : null"
                        name="register-lastname"
                        placeholder="doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- id_umber -->
              <b-form-group
                label="ID Number"
                label-for="id_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ID Number"
                  rules="required|validate-id-number"
                  vid="id_number"
                >
                  <b-form-input
                    id="id_number"
                    v-model="id_number"
                    :state="errors.length > 0 ? false : null"
                    name="id-number"
                    placeholder="*********"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="regEmail"
                    :state="errors.length > 0 ? false : null"
                    name="register-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Phone Number -->
              <b-form-group
                label="Phone Number"
                label-for="phone_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone Number"
                  vid="phone_number"
                  rules="required"
                >
                  <b-form-input
                    id="phone_number"
                    v-model="phone_number"
                    :state="errors.length > 0 ? false : null"
                    name="phone-number"
                    placeholder="07000000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Confirm Password"
                label-for="password_confirmation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password_confirmation"
                      v-model="password_confirmation"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="isLoading"
              >
                {{ !isLoading ? "Sign Up" : "Please wait..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-1">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'auth-login' }">
              <span>Sign in instead</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

extend('validate-id-number', {
  validate: value => {
    let pass = true
    if (value.length < 6 || value.length > 10) {
      pass = false
    }
    return pass
  },
})

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      first_name: '',
      last_name: '',
      id_number: '',
      phone_number: '',
      password: '',
      password_confirmation: '',
      status: '',
      isLoading: false,

      // validation rules
      required,
      email,

      // eslint-disable-next-line global-require
      sideImg: require('@/assets/pavrisk-logo.png'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties, global-require
        this.sideImg = require('@/assets/pavrisk-logo.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    regEmail(oldEmail) {
      this.$http.post('auth/register/check-email', { email: oldEmail })
        .then(() => {})
        .catch(error => {
          this.$refs.registerForm.setErrors(error.response.data.data)
        })
    },
    id_number(oldIdNumber) {
      this.$http.post('auth/register/check-id-number', { id_number: oldIdNumber })
        .then(() => {})
        .catch(error => {
          this.$refs.registerForm.setErrors(error.response.data.data)
        })
    },
    phone_number(oldPhoneNumber) {
      this.$http.post('auth/register/check-phone-number', { phone_number: oldPhoneNumber })
        .then(() => {})
        .catch(error => {
          this.$refs.registerForm.setErrors(error.response.data.data)
        })
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        this.isLoading = true
        if (success) {
          if (!this.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Please accept out Terms & Conditions',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            this.isLoading = false
            return
          }
          useJwt
            .register({
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.regEmail,
              id_number: this.id_number,
              phone_number: this.phone_number,
              password: this.password,
              password_confirmation: this.password_confirmation,
            })
            .then(response => {
              const { user, token, permissions } = response.data

              useJwt.setToken(token)
              localStorage.setItem('user', JSON.stringify(user))
              localStorage.setItem('permissions', JSON.stringify(permissions))
              const abilities = []
              permissions.forEach(ability => {
                abilities.push({
                  action: ability.split(' ')[1],
                  subject: ability.split(' ')[0],
                })
              })
              this.$ability.update(abilities)

              // this.$router.replace(getHomeRouteForLoggedInUser(permissions[0]))
              this.$router.replace({ name: 'auth-verify-email' }).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${user.first_name}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                })
              })
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.errors)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: error.response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        } else {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style>
.brand-img img {
  width: 30%;
  margin: 150px 0 0 80px;
}
@media only screen and (min-width: 998px) {
  .brand-img img {
    position: fixed;
  }
}
@media only screen and (max-width: 998px) {
  .brand-logo {
    display: none;
  }
  .brand-text {
    display: none;
  }
  .brand-img {
    position: relative;
    margin-left: 240px;
    margin-top: -50px;
    margin-bottom: 160px;
  }
  .brand-img img {
    width: 80%;
    margin: 100px 0 0 -40px;
  }
  .auth-bg {
    height: 400px;
    margin-top: -250px;
  }
}
@media only screen and (max-width: 570px) {
  .brand-logo {
    display: none;
  }
  .brand-img {
    position: relative;
    margin-left: 100px;
    margin-top: -40px;
    margin-bottom: 160px;
  }
  .brand-img img {
    width: 100%;
    margin: 100px 0 0 -30px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
